export type Position = {
  left?: number;
  top?: number;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function generateRandomObjects(): { obj1: Position; obj2: Position } {
  const { width, height } = getWindowDimensions();
  const randomTopBottom = () =>
    Math.floor(Math.random() * (100 - -50 + 1)) + -50;
  const randomLeftRight = () =>
    Math.floor(Math.random() * (80 - -40 + 1)) + -40;

  const newObj1: Position = {};
  const newObj2: Position = {};

  const isLeft = Math.random() > 0.5;
  const isTop = Math.random() > 0.5;

  if (isLeft) {
    newObj1.left = randomLeftRight();
    newObj2.left = randomLeftRight() + width * 0.64;
  } else {
    newObj1.left = randomLeftRight() + width * 0.64;
    newObj2.left = randomLeftRight();
  }

  if (isTop) {
    newObj1.top = randomTopBottom();
    newObj2.top = randomTopBottom() + height * 0.64;
  } else {
    newObj1.top = randomTopBottom() + height * 0.64;
    newObj2.top = randomTopBottom();
  }

  return { obj1: newObj1, obj2: newObj2 };
}
