import styled, { keyframes } from "styled-components";

export const LoadingPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(29, 27, 32, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-bottom: 330px;
  gap: 8px;
`;

const topDown = keyframes`
  0% {
    /* padding-bottom: 0; */
    transform: translateY(0);
  }
  50% {
    /* padding-bottom: 20px; */
    transform: translateY(20px);
  }
  100% {
    /* padding-bottom: 0; */
    transform: translateY(0);
  }
`;

export const LoadingPageRobot = styled.img`
  width: 240px;
  height: 262px;

  animation: ${topDown} linear 3s infinite;
`;

export const LoadingPageText = styled.div`
  font-family: Jura, sans-serif;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  display: flex;
`;

const disapear = keyframes`
  0% {
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const LoadingPageSpace = styled.div`
  font-family: Jura, sans-serif;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 163, 255, 0.77);
  animation: ${disapear} ease 1s infinite;
`;
