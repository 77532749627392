import styled from 'styled-components';

import { ReactComponent as HomeSvg } from '../../assets/svg/home.svg';
import { ReactComponent as PeopleSvg } from '../../assets/svg/people.svg';
import { ReactComponent as RocketSvg } from '../../assets/svg/rocket.svg';
import { ReactComponent as UserSvg } from '../../assets/svg/user.svg';

export const NavigationContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 94px;
  bottom: 0;
  background: linear-gradient(0deg, #1d1b20 0%, #1d1b20 100%);
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 20px;
  padding-top: 18px;
`;

export const NavigationIconContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
  width: 48px;
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.7)};
`;

export const NavigationIconText = styled.span`
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

export const HomeIcon = styled(HomeSvg)`
  width: 24px;
  height: 24px;
`;

export const PeopleIcon = styled(PeopleSvg)`
  width: 24px;
  height: 24px;
`;

export const RocketIcon = styled(RocketSvg)`
  width: 24px;
  height: 24px;
`;

export const UserIcon = styled(UserSvg)`
  width: 24px;
  height: 24px;
`;
