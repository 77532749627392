import styled from 'styled-components';

export const AppBackground = styled.div`
  width: 0;
  height: 0;
  z-index: -100;
`;

export const BGLight = styled.span`
  width: 55vw;
  height: 55vw;
  position: fixed;

  border-radius: 50%;
  display: inline-block;

  background: linear-gradient(0deg, #2970ff 0%, #2ecdfd 100%);
  filter: blur(130px);
  z-index: -100;
  transition: ease-in-out 2s;
`;
