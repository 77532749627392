import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  HomeIcon,
  NavigationContainer,
  NavigationIconContainer,
  NavigationIconText,
  PeopleIcon,
  RocketIcon,
  UserIcon,
} from "./navigation.styles";

enum NavPath {
  HOME,
  LIDERS,
  PROMO,
  STATS,
}

const Navigation = () => {
  const [selectedPath, setSelectedPath] = useState(NavPath.HOME);
  const [isNotNeeded, setIsNotNeeded] = useState(false);
  const nav = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("home")) {
      setSelectedPath(NavPath.HOME);
      setIsNotNeeded(false);
    } else if (location.pathname.includes("liders")) {
      setSelectedPath(NavPath.LIDERS);
      setIsNotNeeded(false);
    } else if (location.pathname.includes("stats")) {
      setSelectedPath(NavPath.STATS);
      setIsNotNeeded(false);
    } else if (location.pathname.includes("promo")) {
      setSelectedPath(NavPath.PROMO);
      setIsNotNeeded(false);
    } else setIsNotNeeded(true);
  }, [location.pathname]);

  const userNavigete = (to: string) => {
    if (location.pathname.includes("partner")) nav(`/partner/${to}`);
    else nav(`/${to}`);
  };

  const navigate = (to: NavPath) => {
    const isAdmin = location.pathname.includes("admin");

    switch (to) {
      case NavPath.HOME:
        isAdmin ? nav("/admin/home") : userNavigete("home");
        break;
      case NavPath.LIDERS:
        isAdmin ? nav("/admin/liders") : userNavigete("liders");
        break;
      case NavPath.PROMO:
        userNavigete("promo");
        break;
      case NavPath.STATS:
        isAdmin ? nav("/admin/stats") : userNavigete("stats");
        break;
    }
    setSelectedPath(to);
  };

  if (isNotNeeded) return <></>;

  return (
    <NavigationContainer>
      <NavigationIconContainer
        $isSelected={selectedPath === NavPath.HOME}
        onClick={() => navigate(NavPath.HOME)}>
        <HomeIcon />
        <NavigationIconText>Домой</NavigationIconText>
      </NavigationIconContainer>
      <NavigationIconContainer
        $isSelected={selectedPath === NavPath.LIDERS}
        onClick={() => navigate(NavPath.LIDERS)}>
        <PeopleIcon />
        <NavigationIconText>Лидеры</NavigationIconText>
      </NavigationIconContainer>
      {location.pathname.includes("partner") && (
        <NavigationIconContainer
          $isSelected={selectedPath === NavPath.PROMO}
          onClick={() => navigate(NavPath.PROMO)}>
          <RocketIcon />
          <NavigationIconText>Промокоды</NavigationIconText>
        </NavigationIconContainer>
      )}
      <NavigationIconContainer
        $isSelected={selectedPath === NavPath.STATS}
        onClick={() => navigate(NavPath.STATS)}>
        <UserIcon />
        <NavigationIconText>Статистика</NavigationIconText>
      </NavigationIconContainer>
    </NavigationContainer>
  );
};

export default Navigation;
