import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  color: white;
  font-family: Unbounded, sans-serif;
  color: rgba(255, 255, 255, 1);
  background: #1D1B20;
  overflow-x: hidden;


  .hidden {
    display: none;
  }
}
`;
