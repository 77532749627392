import React, { useEffect, useState } from 'react';

import robotImg from '../../assets/images/robot.png';
import {
  LoadingPageContainer,
  LoadingPageRobot,
  LoadingPageSpace,
  LoadingPageText,
} from './loading-page.styles';

const LoadingPage = () => {
  const [text, setText] = useState('');

  useEffect(() => {
    const loadingText = 'Loading';
    loadingText
      .split('')
      .forEach((l, i) =>
        setTimeout(
          () => setText(loadingText.slice(0, loadingText.indexOf(l) + 1)),
          100 * i
        )
      );

    setTimeout(() => {
      setInterval(() => {
        let mockText = loadingText;
        setText(mockText.replace('o', '6'));
      }, 600);
      setInterval(() => {
        let mockText = loadingText;
        setText(mockText.replace('d', '3'));
      }, 900);
      setInterval(() => {
        let mockText = loadingText;
        setText(mockText.replace('g', '8'));
      }, 700);
    }, 100 * loadingText.length);
  }, []);

  return (
    <LoadingPageContainer>
      <LoadingPageRobot src={robotImg} />
      <LoadingPageText>
        {text}
        <LoadingPageSpace>_</LoadingPageSpace>
      </LoadingPageText>
    </LoadingPageContainer>
  );
};

export default LoadingPage;
